import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import AboutPageHeader from "../../components/aboutpageheader"

import * as styles from "../../styles/pages/about/facilities.module.css"

import facilitieslist from "../../content/about/facilities.json"

const imgatlanticcoatings = "../../images/about/facilities/atlanticcoatings.jpg"
const imgcavitefacility = "../../images/about/facilities/cavitefacility.jpg"
const imgcomms = "../../images/about/facilities/comms.jpg"
const imglagunafacility = "../../images/about/facilities/lagunafacility.jpg"
const imgmainoffice = "../../images/about/facilities/mainoffice.jpg"
const imgmarketing = "../../images/about/facilities/marketing.jpg"
const imgquezoncity = "../../images/about/facilities/quezoncity.jpg"
const imgthecolorlibrary = "../../images/about/facilities/thecolorlibrary.jpg"
const imgthecolorlibrarylogo = "../../images/about/facilities/thecolorlibrarylogo.png"

const AboutFacilitiesPage = () => (
	<Layout activePath='about/facilities' pageTitle="Facilities" pageDescription="">
		<AboutPageHeader activemenu="about/facilities" />
		<h2 className={"text-centered futuraboldfont "+styles.maintitle}>PLANT SITES</h2>

		{facilitieslist.map((curfacility, curfacilityidx)=> {
			return <div className={styles.itemrow}>
				<div className={styles.itemimage}>
					{curfacilityidx === 0 && <StaticImage layout={"fullWidth"}  src={imgatlanticcoatings} alt={curfacility.name} />}
					{curfacilityidx === 1 && <StaticImage layout={"fullWidth"}  src={imgcavitefacility} alt={curfacility.name} />}
					{curfacilityidx === 2 && <StaticImage layout={"fullWidth"}  src={imglagunafacility} alt={curfacility.name} />}
					{curfacilityidx === 3 && <StaticImage layout={"fullWidth"}  src={imgquezoncity} alt={curfacility.name} />}
					{curfacilityidx === 4 && <StaticImage layout={"fullWidth"}  src={imgmainoffice} alt={curfacility.name} />}
					{curfacilityidx === 5 && <StaticImage layout={"fullWidth"}  src={imgmarketing} alt={curfacility.name} />}
					{curfacilityidx === 6 && <StaticImage layout={"fullWidth"}  src={imgcomms} alt={curfacility.name} />}

				</div>
				<div className={styles.itemcontent}>
					<div className={"font-style-bold "+styles.itemtitle}>{curfacility.name}</div>
					<div className={styles.itemtext} dangerouslySetInnerHTML={{__html:curfacility.details.replace(/\n/g, "<br/>\n")}} />
				</div>
			</div>
		})}

		<h2 className={"text-centered futuraboldfont "+styles.maintitle+" "+styles.maintitlepadding}>CONCEPT STORE</h2>
		<div>
			<StaticImage layout={"fullWidth"}  src={imgthecolorlibrary} alt={"The Color Library"} />
		</div>
		<div className={styles.thecolorlibrarylogo}>
			<StaticImage layout={"fullWidth"}  src={imgthecolorlibrarylogo} alt={"The Color Library Logo"} />
		</div>
		<div className={"text-centered "+styles.thecolorlibrarytext}>
			<div>
				Visit The Color Library, a one-of-a kind concept store that's home to 1,300 handpicked <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span> paint colors.
			</div>
			<div className={styles.thecolorlibrarylearnmore}>
				<a target="_blank" rel="noreferrer" href="https://www.myboysen.com/the-color-library-faqs/" title="Learn more">Learn more</a>
			</div>

			<div className={"font-style-bold "+styles.thecolorlibraryvisitus}>
				VISIT US AT
			</div>
			<div>
				SP 241-242 2nd Level, IKEA Retail Integration, South Parking Building, SM Mall of Asia, Pasay City 1300
			</div>
			<div>
				<a href="tel:+63287411219">(02) 8741-1219</a>
			</div>
		</div>
	</Layout>
)

export default AboutFacilitiesPage
