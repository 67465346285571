// extracted by mini-css-extract-plugin
export var itemtitle = "facilities-module--itemtitle--bafdf";
export var maintitle = "facilities-module--maintitle--52c32";
export var itemtext = "facilities-module--itemtext--29e78";
export var maintitlepadding = "facilities-module--maintitlepadding--817b1";
export var itemrow = "facilities-module--itemrow--9c075";
export var itemimage = "facilities-module--itemimage--8be42";
export var itemcontent = "facilities-module--itemcontent--ecb88";
export var thecolorlibrarylogo = "facilities-module--thecolorlibrarylogo--1ed3d";
export var thecolorlibrarytext = "facilities-module--thecolorlibrarytext--6f652";
export var thecolorlibraryvisitus = "facilities-module--thecolorlibraryvisitus--f5b6c";
export var thecolorlibrarylearnmore = "facilities-module--thecolorlibrarylearnmore--a048b";